import { send, upload } from "../index";

export function getPerformTagList(data, opts = {}) {
    // 演出标签列表
    return send({
        url: "/admin/performTag/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePerformTag(data, opts = {}) {
    // 创建演出标签
    return send({
        url: "/admin/performTag/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePerformTag(data, opts = {}) {
    // 修改演出标签
    return send({
        url: "/admin/performTag/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePerformTag(data, opts = {}) {
    // 删除演出标签
    return send({
        url: "/admin/performTag/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformTipsList(data, opts = {}) {
    // 查询演出须知/服务列表
    return send({
        url: "/admin/performTips/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePerformTips(data, opts = {}) {
    // 创建演出须知
    return send({
        url: "/admin/performTips/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePerformTips(data, opts = {}) {
    // 修改演出须知/服务
    return send({
        url: "/admin/performTips/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformTipsDetail(data, opts = {}) {
    // 演出须知/服务详情
    return send({
        url: "/admin/performTips/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePerformTips(data, opts = {}) {
    // 删除演出须知
    return send({
        url: "/admin/performTips/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformNavigationList(data, opts = {}) {
    // 查询导航列表
    return send({
        url: "/admin/performNavigation/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePerformNavigation(data, opts = {}) {
    // 新增导航
    return send({
        url: "/admin/performNavigation/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePerformNavigation(data, opts = {}) {
    // 修改导航
    return send({
        url: "/admin/performNavigation/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePerformNavigation(data, opts = {}) {
    // 删除导航
    return send({
        url: "/admin/performNavigation/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformClassifyList(data, opts = {}) {
    // 查询演出分类列表
    return send({
        url: "/admin/performClassify/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformClassifyDetail(data, opts = {}) {
    // 演出分类详情
    return send({
        url: "/admin/performClassify/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePerformClassify(data, opts = {}) {
    // 新增演出分类
    return send({
        url: "/admin/performClassify/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePerformClassify(data, opts = {}) {
    // 修改演出分类
    return send({
        url: "/admin/performClassify/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePerformClassify(data, opts = {}) {
    // 删除演出分类
    return send({
        url: "/admin/performClassify/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledformClassify(data, opts = {}) {
    // 演出分类启用/禁用
    return send({
        url: "/admin/performClassify/switch.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformSiteList(data, opts = {}) {
    // 场地管理列表
    return send({
        url: "/admin/performSite/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformSiteDetail(data, opts = {}) {
    // 场地管理详情
    return send({
        url: "/admin/performSite/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePerformSite(data, opts = {}) {
    // 场地管理创建
    return send({
        url: "/admin/performSite/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePerformSite(data, opts = {}) {
    // 场地管理修改
    return send({
        url: "/admin/performSite/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledPerformSite(data, opts = {}) {
    // 场地管理启用/禁用
    return send({
        url: "/admin/performSite/switch.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePerformSite(data, opts = {}) {
    // 场地管理删除
    return send({
        url: "/admin/performSite/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformList(data, opts = {}) {
    // 演出列表
    return send({
        url: "/admin/performInfo/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformDetail(data, opts = {}) {
    // 演出详情
    return send({
        url: "/admin/performInfo/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}


export function savePerform(data, opts = {}) {
    // 新增演出
    return send({
        url: "/admin/performInfo/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePerform(data, opts = {}) {
    // 修改演出
    return send({
        url: "/admin/performInfo/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledPerform(data, opts = {}) {
    // 演出启用/禁用
    return send({
        url: "/admin/performInfo/switch.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformSchedulesList(data, opts = {}) {
    // 演出场次列表
    return send({
        url: "/admin/performSchedules/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformSchedulesDetail(data, opts = {}) {
    // 演出场次详情
    return send({
        url: "/admin/performSchedules/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePerformSchedules(data, opts = {}) {
    // 演出场次新增
    return send({
        url: "/admin/performSchedules/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePerformSchedules(data, opts = {}) {
    // 演出场次编辑
    return send({
        url: "/admin/performSchedules/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledPerformSchedules(data, opts = {}) {
    // 演出场次启用/禁用
    return send({
        url: "/admin/performSchedules/switch.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePerformSchedules(data, opts = {}) {
    // 演出场次删除
    return send({
        url: "/admin/performSchedules/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformRefundRulesList(data, opts = {}) {
    // 售后规则列表
    return send({
        url: "/admin/performRule/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformRefundRulesDetail(data, opts = {}) {
    // 售后规则详情
    return send({
        url: "/admin/performRule/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePerformRefundRules(data, opts = {}) {
    // 售后规则新增
    return send({
        url: "/admin/performRule/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePerformRefundRules(data, opts = {}) {
    // 售后规则修改
    return send({
        url: "/admin/performRule/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledPerformRefundRules(data, opts = {}) {
    // 售后规则启用/禁用
    return send({
        url: "/admin/performRule/switch.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePerformRefundRules(data, opts = {}) {
    // 售后规则删除
    return send({
        url: "/admin/performRule/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformRecommendList(data, opts = {}) {
    // 每日必抢列表
    return send({
        url: "/admin/performRecommend/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformRecommendDetail(data, opts = {}) {
    // 每日必抢详情
    return send({
        url: "/admin/performRecommend/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePerformRecommend(data, opts = {}) {
    // 每日必抢新增
    return send({
        url: "/admin/performRecommend/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePerformRecommend(data, opts = {}) {
    // 每日必抢编辑
    return send({
        url: "/admin/performRecommend/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledPerformRecommend(data, opts = {}) {
    // 每日必抢启用/禁用
    return send({
        url: "/admin/performRecommend/switch.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePerformRecommend(data, opts = {}) {
    // 每日必抢删除
    return send({
        url: "/admin/performRecommend/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformStrategyList(data, opts = {}) {
    // 演出攻略列表
    return send({
        url: "/admin/performStrategy/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformStrategyDetail(data, opts = {}) {
    // 演出攻略详情
    return send({
        url: "/admin/performStrategy/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePerformStrategy(data, opts = {}) {
    // 演出攻略新增
    return send({
        url: "/admin/performStrategy/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePerformStrategy(data, opts = {}) {
    // 演出攻略修改
    return send({
        url: "/admin/performStrategy/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledPerformStrategy(data, opts = {}) {
    // 演出攻略启用/禁用
    return send({
        url: "/admin/performStrategy/switch.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePerformStrategy(data, opts = {}) {
    // 演出攻略删除
    return send({
        url: "/admin/performStrategy/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformOrderList(data, opts = {}) {
    // 演出订单列表
    return send({
        url: "/admin/performOrder/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPerformSeatList(data, opts = {}) {
    // 演出场次座位列表
    return send({
        url: "/admin/performSeat/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function performSeatSend(data, opts = {}) {
    // 发放座位
    return send({
        url: "/admin/performSeat/send.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledPerformSeat(data, opts = {}) {
    // 启用/禁用座位
    return send({
        url: "/admin/performSeat/sealing.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function clearPerformSeat(data, opts = {}) {
    // 清空单个座位分配
    return send({
        url: "/admin/performSeat/clear.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function clearSchedulePerformSeat(data, opts = {}) {
    // 清空场次座位分配
    return send({
        url: "/admin/performSeat/clearSchedule.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function dividePerformSeat(data, opts = {}) {
    // 手动分配座位
    return send({
        url: "/admin/performSeat/divide.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function autoDividePerformSeat(data, opts = {}) {
    // 自动分配座位
    return send({
        url: "/admin/performSeat/autoDivide.do",
        method: "POST",
        data,
        ...opts,
    });
}